import tpl from './spinner.component.html';
import { Component, BaseComponent, Events } from '../util';
import Helpers from '../utils';
const time = 250;

@Component({ tpl })
export class SpinnerComponent extends BaseComponent {
  connectedCallback() {
    this.ui = Helpers.uiHandler(
      [
        'body',
        Events.LOADING_START,
        ({ detail }) => this.setLoading(true, detail),
      ],
      [
        'body',
        Events.LOADING_STOP,
        ({ detail }) => this.setLoading(false, detail),
      ],
      ['.loading']
    );
    this.state = Helpers.doter(this.ui.loading, 'loading');

    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
      doc.style.setProperty('--doc-width', `${window.innerWidth}px`);
    };
    window.addEventListener('resize', documentHeight);
    documentHeight();
  }

  setLoading(value, key) {
    this.state[value ? 'start' : 'stop'].bind(this)(key);
    setTimeout(
      () => this.ui.loading.classList.toggle('hidden', !value),
      value ? 0 : time
    );
  }

  loading() {
    const dots = ['⠟', '⠻', '⠽', '⠾', '⠷', '⠯'];
    let i = 0;
    const isActive = {};
    let interval;
    const run = () => {
      dispatch(text(dots));
      interval = setInterval(
        () =>
          dispatch(
            Reflect.ownKeys(isActive).length
              ? text(dots)
              : (interval = clearInterval(interval))
          ),
        120
      );
    };
    const text = (d) => `${d[++i % d.length]} loading`;
    const dispatch = (detail) => (this.ui.loading.innerHTML = detail);
    return {
      start(id) {
        !Reflect.ownKeys(isActive).length && run();
        isActive[id] = true;
        setTimeout(() => {
          if (isActive[id]) {
            console.warn(`request ${id.toString()} timeout`);
            this.stop(id);
          }
        }, 10000);
      },
      stop(id) {
        setTimeout(() => delete isActive[id], time);
      },
    };
  }

  render(data) {
    this.body.innerHTML = `${data}`;
  }
}

customElements.define('ui-spinner', SpinnerComponent);
